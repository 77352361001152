import { Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { CoinFlip } from "../../../components/coin"
import { SlideIn } from "../../../components/slide"
import { useEffect, useState } from "react"
import HeadImg from "../../../assets/imgs/express/MOEDA CARA.png"
import TailImg from "../../../assets/imgs/express/MOEDA COROA.png"
import { formatCurrency, NameMask } from "../../../utils/mask"
import useAuth from "../../../context/auth"
import { AnimatePresence } from "framer-motion"
import { ExpressService } from "../../../service/express"
import { toast } from "react-toastify"
import { Sounds } from "../audios/intex"
import { useScript } from "../../../hooks/useScript"
import { Effects } from "../effects"

interface Props {
    open: boolean,
    value: number,
    betId: number,
    onHandleClose: () => void,
}

export const CoinModal = ({
    open,
    value,
    betId,
    onHandleClose
}: Props) => {
    useScript("https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js")
    const { setUser, user } = useAuth()
    const [coinResult, setCoinResult] = useState(null)
    const [finish, setFinish] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
    const [hasClicked, setHasClicked] = useState(false)
    const closeTime = 7
    const animationTime = 0.5
    const { firstName, lastName } = NameMask(user?.name ?? "")
    const [initAutoClick, setInitAutoClick] = useState(false)

    const flipCoin = () => {
        if (!hasClicked) {
            setHasClicked(true)
            Sounds.playClick()
            ExpressService.playCoin(betId)
                .then(({ data }) => {
                    setCoinResult(data?.result)
                    Sounds.playCoin()
                    if (data?.client) {
                        setUser({
                            ...user,
                            credits: data.client?.credits,
                            guessCredits: data.client?.guessCredits,
                            coinCredit: data.client?.coinCredit
                        })
                    }
                })
                .catch((err) => {
                    toast(err?.response?.data?.message ?? "Falha ao girar a moeda", {
                        type: "error"
                    })

                    setCoinResult(null)
                    setFinish(false)
                    setIsVisible(false)

                    setTimeout(() => {
                        onHandleClose()
                        setIsVisible(true)
                        setHasClicked(false)
                    }, animationTime * 1000);
                })
        }
    }

    useEffect(() => {
        if (typeof coinResult === "number") {
            setTimeout(() => {
                setCoinResult(null)
                setFinish(false)
                setIsVisible(false)
                setTimeout(() => {
                    setInitAutoClick(false)
                    setHasClicked(false)
                    onHandleClose()
                    setIsVisible(true)
                }, animationTime * 1000);
            }, closeTime * 1000);
        }
    }, [coinResult])

    useEffect(() => {
        if (finish) {
            if (coinResult === 1) {
                Effects.congratulations()
            } else {
                Sounds.playCashregister()
            }
        }
    }, [finish])

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setInitAutoClick(true)
            }, 6000);
        }
    }, [open])

    useEffect(() => {
      if (initAutoClick && !hasClicked) {
        flipCoin()
      }
    }, [initAutoClick, hasClicked])
    
    return (
        <Dialog
            fullScreen
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0, 0.5)',
                    boxShadow: 'none',
                },
            }}
        >
            <DialogContent onClick={flipCoin}>
                <AnimatePresence mode="wait">
                    {isVisible &&
                        <SlideIn key={"coin"} direction="bottom" duration={animationTime} type="spring" usingModal>
                            <Grid display={"flex"} gap={2} width={"100%"} mt={10} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                {!finish && <CoinFlip key={'sdjskhg'} result={coinResult} onFinish={() => typeof coinResult === "number" && setFinish(true)} />}
                            </Grid>

                            {!finish &&
                                <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mt={4}>
                                    <Typography variant={"h3"} textAlign={"center"} textTransform={"uppercase"} color={"white"} fontWeight={"bold"}>CARA OU COROA</Typography>
                                    <Typography variant={"h4"} textAlign={"center"} textTransform={"uppercase"} color={"white"} fontWeight={"bold"}>Receba <span style={{color: "#9f9fa0"}}>seu prêmio</span> ou <span style={{color: "rgb(247 181 0)"}}>o dobro</span></Typography>
                                </Grid>
                            }

                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} gap={4} mt={4}>
                                {(!finish || coinResult === 0) && <Grid container sx={{
                                    transition: `all ${closeTime}s ease`,
                                    ...(finish && {
                                        transform: 'scale(1.15)'
                                    }),
                                    ml: -2
                                }} display={"flex"} flexWrap={"nowrap"} flexDirection={finish ? "column" : "row"} alignItems={"center"} justifyContent={"center"} gap={4}>
                                    <img src={HeadImg} alt="Cara" style={{
                                        width: '80px',
                                        height: 'auto',
                                        transform: 'rotate(90deg)'
                                    }} />
                                    <Typography variant="h4" color={"white"} fontWeight={"bold"} textAlign={"center"}> {finish ? `${firstName} ${lastName}` : ''}<br />{finish ? "Você recebeu " : "Seu prêmio = "} {formatCurrency(value)}</Typography>
                                </Grid>}
                                {(!finish || coinResult === 1) && <Grid container sx={{
                                    transition: `all ${closeTime}s ease`,
                                    ...(finish && {
                                        transform: 'scale(1.15)'
                                    })
                                }} display={"flex"} flexWrap={"nowrap"} flexDirection={finish ? "column" : "row"} alignItems={"center"} justifyContent={"center"} gap={4}>
                                    <img src={TailImg} alt="Coroa" style={{
                                        width: '80px',
                                        height: 'auto',
                                        transform: 'rotate(90deg)'
                                    }} />
                                    <Typography variant="h4" color={finish ? "#FFD700" : "white"} fontWeight={"bold"} textAlign={"center"}> {finish ? `${firstName} ${lastName}` : ''}<br />{finish ? <>Parabéns!<br />Você ganhou </> : "Seu prêmio x2 = "} {formatCurrency(value * 2)}</Typography>
                                </Grid>}
                            </Grid>

                            {!finish && <Typography variant="h6" textAlign={"center"} color={"white"} fontWeight={"bold"} mt={4}>Clique na moeda para tentar<br /> multiplicar o seu prêmio</Typography>}
                        </SlideIn>}
                </AnimatePresence >
            </DialogContent>
        </Dialog>
    )
}