import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@mui/material"
import { BiMoneyWithdraw } from "react-icons/bi";
import { GoAlertFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { suportNumber } from "utils";

export const ModalFraude = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
    const { palette } = useTheme()
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <Grid sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <GoAlertFill color="orange" size={30} />
                    <Typography variant="h2" color={"red"}>ATENÇÃO CUIDADO COM GOLPES</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Typography fontSize={16}>🚫 <strong>Não</strong> fazemos ligações para clientes</Typography>
                    <Typography fontSize={16}><BiMoneyWithdraw size={16} color={palette.primary.main} style={{marginBottom: -2}}/> Para <strong>SACAR</strong> seus prêmios clique acima em <BiMoneyWithdraw size={16} color={palette.common.black} style={{marginBottom: -2}}/> SAQUE AQUI</Typography>
                </Grid>
                <Typography mt={2} padding={'0px 20px'} textAlign={"center"}>
                    Em caso de dúvida clique acima e entre em contato com nosso <Link to={`https://wa.me/+55${suportNumber}`} target="_blank">ATENDIMENTO</Link>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}