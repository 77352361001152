import { LPNav } from '../../layout/lp';
import Banner from 'assets/imgs/lp/whoWeAre/BANNER QUEM SOMOS.png'
import Step from 'assets/imgs/lp/whoWeAre/STEP.png'
import Check from 'assets/imgs/lp/whoWeAre/check.svg'

import style from './styles.module.css'
import { useTheme } from '@mui/material';
import { RodapeLP } from 'layout/lp/rodape';
import { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import "video-react/dist/video-react.css";
import UserProps from 'types/user';
import StorageService from '../../service/storage';
import { AuthService } from '../../service/auth';
import { constants } from '../../utils';
import BannerNewPrizes from '../../assets/imgs/lp/whoWeAre/PRÊMIO DA QUADRA SITE.png'
import { VideoCalango } from './videos';


export function LP() {
    const theme = useTheme()
    const navigate = useNavigate();

    function getHighlightedText(text: string, highlight: string) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <h2 > {parts.map((part, i) =>
            <span className={style.underline} key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { textDecoration: 'underline', textDecorationColor: theme.palette.primary.main, color: theme.palette.primary.main } : {}}>
                {part}
            </span>)
        } </h2>;
    }

    const [indication, setIndication] = useState<boolean | null>(true)
    const query = useQuery()
    const [client, setClient] = useState(query.get('c'))
    const [correspondent, setCorrespondent] = useState(!query.get('s') || query.get('s') === "" ? constants.homeUser : query.get('s'))

    const [clientData, setClientData] = useState<UserProps>()

    const onClick = () => {
        let query = "?"
        if (client) {
            query += `c=${client}&`
        }
        if (correspondent) {
            if (correspondent !== constants.homeUser) {
                query += `s=${correspondent}&`
            } else if (clientData?.lastSellerId) {
                query += `s=${clientData?.lastSellerId}&`
            } else {
                query += `s=${correspondent}&`
            }
        }
        navigate("/cliente/concurso" + query)
    }

    useEffect(() => {
        if (StorageService.getTokeClient()) {
            AuthService.clientMe().then(({ data }) => {
                setClientData(data)
            })
        }
    }, [])

    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.firtContainer} onClick={onClick}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <h1>Aqui <span>sua sorte</span> não acumula!</h1>
                    <p>A plataforma <strong>NÚMERO FAVORITO</strong> é uma nova modalidade de <strong>entretenimento pessoal</strong>, classificada como uma Startup FunTech, não se enquadra estruturalmente como um sorteio tradicional, tornou-se uma das maiores referências em FunTech, startup que utiliza <strong>inovação tecnológica para transformar o setor de entretenimento e lazer.</strong></p>
                    <p>Nosso propósito é revolucionar sua experiência com o entretenimento, oferecendo algo mais que diversão. Acreditamos que um relacionamento baseado na <strong>credibilidade, honestidade</strong> e <strong>transparência</strong> possibilita o sucesso contínuo. Estamos cientes de que <strong>sua vitória é o nosso sucesso!</strong></p>
                    {indication &&
                        <div className='divStamp' style={{
                            marginBottom: 10,
                            alignSelf: 'center'
                        }}>
                            <button onClick={onClick} className="buttonNow">Aposte agora!</button>
                        </div>}
                </div>
                <div>
                    <img src={Banner} alt="banner" />
                </div>
            </div>
            <div className={style.secondContainer}>
                {/* <div>
                    <h2>Assista nosso vídeo e conheça {useMediaQuery('(min-width:1330px)') && <br />}mais o <span>NÚMERO FAVORITO</span></h2>
                    <video controls controlsList="nodownload" preload="auto" playsInline poster={isMobile() ? ThumbMobile : ThumbDesktop}>
                        <source src={isMobile() ? "https://s3.amazonaws.com/static.numerofavorito.com/videos+lp/mobile.mp4" : "https://s3.amazonaws.com/static.numerofavorito.com/videos+lp/desktop.mp4"} type="video/mp4" />
                    </video>
                    <h2>É <span>muito simples</span> participar!</h2>

                </div> */}
                {/* <div>
                    <img src={Icon} />
                    <img src={HandCellphone} alt="mão segurando o celular" />
                </div> */}
            </div>
            {/* <div className={style.thirdContainer}>
                <div className={style.step}>
                    <div>
                        <img src={Step} alt="step img" />
                        <div />
                    </div>
                    <div>
                        <h2>Passo 1</h2>
                        <p>• Selecione o dia e o horário do seu concurso</p>
                    </div>
                </div>
                <div className={style.step}>
                    <div>
                        <img src={Step} alt="step img" />
                        <div />
                    </div>
                    <div>
                        <h2>Passo 2</h2>
                        <p>• Escolha a quantidade de apostas</p>
                    </div>
                </div>
                <div className={style.step}>
                    <div>
                        <img src={Step} alt="step img" />
                        <div />
                    </div>
                    <div>
                        <h2>Passo 3</h2>
                        <p style={{
                            marginBottom: 80
                        }}>• Preencha suas apostas com os números de sua preferência</p>
                    </div>
                </div>
                <div className={style.goodLuck}>
                    <img src={Check} alt="check img" />
                    <h2>Boa sorte!</h2>
                </div>
            </div> */}
            <div className={style.containerNewPrizes}>
                <img src={BannerNewPrizes}></img>
            </div>
            <VideoCalango />
            <div className={style.fourContainer} onClick={onClick}>
                <p>SORTE EXTRA! Aproximou, Ganhou.<span><br />É muito, muito, muito, muito</span> fácil de ganhar na hora até <span>50.000 vezes</span> o valor da aposta.</p>
                <p>Participe e ganhe <span>muito dinheiro todo dia</span></p>
                {indication &&
                    <div className='divStamp'>
                        <button onClick={onClick} className="buttonNow">Clique aqui para apostar agora!</button>
                    </div>
                }
                {getHighlightedText("Aqui sua sorte não acumula!", "sua sorte")}

            </div>
            <RodapeLP />
        </div>
    )
}