import React, { createContext, useContext, useState, ReactNode } from 'react';

// Tipos para o Contexto
interface BlockerContextType {
  isBlocked: boolean;
  setIsBlocked: (blocked: boolean) => void;
}

// Contexto para o bloqueio global
const BlockerContext = createContext<BlockerContextType | undefined>(undefined);

// Provedor para o contexto
interface GlobalBlockerProviderProps {
  children: ReactNode;
}

export const GlobalBlockerProvider: React.FC<GlobalBlockerProviderProps> = ({ children }) => {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  return (
    <BlockerContext.Provider value={{ isBlocked, setIsBlocked }}>
      {children}
      {isBlocked && <div className="global-blocker" />}
    </BlockerContext.Provider>
  );
};

// Hook para usar o bloqueio
export const useGlobalBlocker = (): BlockerContextType => {
  const context = useContext(BlockerContext);
  if (!context) {
    throw new Error('useGlobalBlocker must be used within a GlobalBlockerProvider');
  }
  return context;
};
