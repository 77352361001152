import { Howl, Howler } from 'howler';

// Definindo um tipo para o objeto de sons
type SoundMap = {
    [key: string]: Howl;
};

// Objeto para armazenar os sons
const sounds: SoundMap = {};

// Função para carregar um som
const loadSound = (name: string, src: string, volume: number = 0.1, loop: boolean = false) => {
    const sound = new Howl({
        src: [src],
        volume,
        html5: true, // Opcional: para streaming de áudio,
        loop
    });
    sounds[name] = sound; // Armazena o som no objeto sounds
};

// Função para tocar o som

export class Sounds {
    // Ajusta o volume geral de todos os sons
    public static setGlobalVolume = (volume: number) => {
        Howler.volume(volume); // Volume global de 0.0 a 1.0
    };

    public static playPrizeDraw = () => {
        if (sounds.prizeDraw) {
            sounds.prizeDraw.play();
        } else {
            loadSound('prizeDraw', require('../../../assets/sounds/express/GIRO DA ROLETA.mp3'));
            sounds.prizeDraw.play(); // Toca o som imediatamente após carregá-lo
        }
    };

    public static stopPrizeDraw = () => {
        if (sounds.prizeDraw) {
            sounds.prizeDraw.stop();
        }
    };

    public static playClick = () => {
        if (sounds.click) {
            sounds.click.play();
        } else {
            loadSound('click', require('../../../assets/sounds/express/CLICK.mp3'));
            sounds.click.play();
        }
    };

    public static stopClick = () => {
        if (sounds.click) {
            sounds.click.stop();
        }
    };

    // Ajusta o volume individual de um som específico
    public static setSoundVolume = (soundName: string, volume: number) => {
        if (sounds[soundName]) {
            sounds[soundName].volume(volume); // Ajusta o volume de 0.0 a 1.0 para o som específico
        }
    };

    public static playBackgroundSound = () => {
        if (sounds.background) {
            const isPlaying = sounds.background.playing();
            if (!isPlaying) {
                sounds.background.play(); // Toca o som se não estiver tocando
            }
        } else {
            loadSound('background', require('../../../assets/sounds/express/MÚSICA DE FUNDO.mp3'), 0.1, true);
            sounds.background.play();
        }
    }

    public static stopBackgroundSound = () => {
        if (sounds.background) {
            sounds.background.pause();
        }
    };

    public static playCoin = () => {
        if (sounds.coin) {
            sounds.coin.play();
        } else {
            loadSound('coin', require('../../../assets/sounds/express/MOEDA GIRANDO.mp3'));
            sounds.coin.play();
        }
    }

    public static stopCoin = () => {
        if (sounds.coin) {
            sounds.coin.stop();
        }
    };

    public static playApplause = () => {
        if (sounds.applause) {
            sounds.applause.play();
        } else {
            loadSound('applause', require('../../../assets/sounds/express/APLAUDOS.mp3'));
            sounds.applause.play();
        }
    }

    public static stopApplause = () => {
        if (sounds.applause) {
            sounds.applause.stop();
        }
    };

    public static playCashregister = () => {
        if (sounds.cashregister) {
            sounds.cashregister.play();
        } else {
            loadSound('cashregister', require('../../../assets/sounds/express/CAIXA REGISTRADORA.mp3'));
            sounds.cashregister.play();
        }
    }

    public static stopCashRegister = () => {
        if (sounds.cashregister) {
            sounds.cashregister.stop();
        }
    }

    public static playSlotMachine = () => {
        if (sounds.slotmachine) {
            sounds.slotmachine.play();
        } else {
            loadSound('slotmachine', require('../../../assets/sounds/express/Slot Machine Jackpot Sound Effect.mp3'));
            sounds.slotmachine.play();
        }
    }

    public static stopSlotMachine = () => {
        if (sounds.slotmachine) {
            sounds.slotmachine.stop();
        }
    }
}


